<template>
	<div id="newform">
		<!-- 报价蜘蛛 报价单 -->
		<div class="ReferenceForm">
			<div class="title">{{formtit}}</div>
			<div class="form">
				<div class="formName">
					<div class="tit">项目名称</div>
					<el-input v-model="form.quotedPriceName" placeholder="请输入您的设计项目名称"></el-input>
				</div>

				<div class="contacts">
					<div class="tit">联系人信息</div>
					<div class="contactList">
						<div class="row" v-for="(item,i) in form.contacts" :key="i">
							<div class="name">
								<span>姓名</span>
								<el-input v-model="item.contactName" placeholder="请输入联系人姓名"></el-input>
							</div>
							<div class="phone">
								<span>手机号</span>
								<el-input v-model="item.contactPhone" placeholder="请输入联系人手机号" maxlength="11"></el-input>
							</div>
							<div class="delete">
								<el-button v-if="i!=0" icon="el-icon-delete" type="danger"
									@click="deleteRow(i)"></el-button>
							</div>
						</div>
					</div>
					<!-- <el-button type="primary" plain icon="el-icon-plus" @click="addContacts()">添加联系人</el-button> -->
				</div>

				<div class="detailed">
					<div class="tit">报价明细</div>
					<el-table :data="form.detailedBos" style="width: 100%" @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column prop="date" label="服务类目">
							<template slot-scope="scope">
								<div class="col1">
									<!-- <el-image :src="scope.row.imagePath" fit="cover"></el-image> -->
									<div class="info">
										<div class="class">{{scope.row.detaiiledName}}</div>
										<!-- <div class="main">
											<div class="size">
												尺寸：{{`${scope.row.imageSize[0]}x${scope.row.imageSize[1]}`}}</div>
											<div class="type">
												板式：{{scope.row.imageSize[0]>scope.row.imageSize[1]?'横版':'竖版'}}</div>
										</div> -->
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="数量" width="240">
							<template slot-scope="scope">
								<div class="col2">
									<el-input-number size="mini" v-model="scope.row.num" :min="1"></el-input-number>页
								</div>
								<!-- {{ scope.row.num}} -->
							</template>
						</el-table-column>
						<el-table-column prop="address" label="单价" width="100">
							<template slot-scope="scope">
								￥{{ numberToCurrencyNo(scope.row.price) }}
							</template>
						</el-table-column>
						<el-table-column prop="options" label="操作" width="100">
							<template slot-scope="scope">
								<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)"
									icon="el-icon-delete"></el-button>
							</template>
						</el-table-column>
					</el-table>

					<el-button type="primary" plain icon="el-icon-folder-add"
						@click="dialogVisible = true">增加报价明细</el-button>
					<el-button v-if="this.form.detailedBos.length>1" type="danger" plain icon="el-icon-folder-remove"
						@click="deleteActiveForm">去除选中明细</el-button>

					<el-divider></el-divider>

					<div class="TotalPrice">
						总计： <span>￥{{ numberToCurrencyNo(form.price)}}</span>
					</div>
					<div class="submit">
						<el-button type="primary" @click="submitForm">生成报价单</el-button>
					</div>
				</div>
			</div>
		</div>

		<el-dialog :visible.sync="dialogVisible" width="1000" class="subclauses">
			<div class="dialogCont">
				<div class="tree">
					<div class="treeTit">新建服务类型</div>
					<ul class="treeList">
						<li :class="activeId==item.id? 'item active':'item'" v-for="(item) in detailedTreeList"
							:key="item.id" @click="treeItemActive(item)">
							{{item.detaiiledName}}
						</li>
					</ul>
				</div>
				<div class="cont">
					<div class="contTit">
						常用
					</div>
					<el-checkbox-group v-model="form.detailedBos">
						<el-checkbox :label="item" v-for="item in rowData" :key="item.id">
							<i class="el-icon-plus"></i>
							{{item.detaiiledName}}
						</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>

			<span slot="footer" class="dialogFooter">
				<!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		detailedTreeList, // 获取报价明细
		saveOrUpdate, // 新增或修改报价单
		quotedPrice // 获取报价单详情
	} from '@/request/apiReference.js';
	const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
	export default {
		name: 'newform',
		components: {},
		data() {
			return {
				formtit: "新建报价单",
				form: {
					quotedPriceName: "", // 报价单名称
					contacts: [{ // ；联系人
						"contactName": "",
						"contactPhone": "",
					}],
					detailedBos: [],
					price: 0 // 价格
				},
				dialogVisible: false,
				detailedTreeList: [],
				multipleSelection: [], // 表格选中项
				activeId: '',
				rowData: []
			}
		},
		created() {
			let queryId = this.$route.query.id
			if (queryId) {
				this.formtit = '修改报价单';
				this.getquotedPrice(queryId);
			} else {
				this.formtit = "新建报价单"
			}
			this.getdetailedTreeList();
		},
		watch: {
			'form.detailedBos': {
				handler(val) {
					this.form.price = 0;
					val.map(item => {
						this.form.price += item.num * item.price
					})
				},
				deep: true
			}
		},
		mounted() {},
		methods: {
			// 获取报价单详情
			getquotedPrice(id) {
				quotedPrice(id).then(res => {
					let data = res.data;
					console.log(JSON.parse(JSON.stringify(data)));
					this.form.quotedPriceName = data.quotedPriceName;
					this.form.detailedBos = data.detaileds.map(item => item.children).flat();
					this.form.contacts = data.contacts;
					this.form.id = data.id;

				})
			},

			// 报价明细切换
			treeItemActive(item) {
				this.activeId = item.id;
				this.rowData = item.children;
			},

			// 获取报价明细列表
			getdetailedTreeList() {
				detailedTreeList().then(res => {
					if (res.code == 200) {
						let data = res.rows
						this.detailedTreeList = data;
						this.rowData = data[0].children
						this.activeId = data[0].id
					}
				})
			},

			// 
			handleSelectionChange(val) {
				this.multipleSelection = val.map(item => item.id);
			},

			// 删除多条选中明细
			deleteActiveForm() {
				this.multipleSelection.map(id => {
					let index = this.form.detailedBos.findIndex(item => item.id == id);
					this.form.detailedBos.splice(index, 1);
				})
			},

			// 删除单条选中明细
			handleDelete(idx /*, item*/ ) {
				this.form.detailedBos.splice(idx, 1)
			},

			// 提交表单
			submitForm() {
				if (!this.form.quotedPriceName) {
					this.$notify({
						title: '提示',
						message: `请填写设计项目名称`,
						type: 'error',
						position: 'bottom-right'
					});
				} else if (!this.form.contacts[0].contactName || !this.form.contacts[0].contactPhone) {
					this.$notify({
						title: '提示',
						message: `请完善联系人信息`,
						type: 'error',
						position: 'bottom-right'
					});
				} else if (!reg_tel.test(this.form.contacts[0].contactPhone)) {
					this.$notify({
						title: '提示',
						message: `请正确填正确的手机号码！`,
						type: 'error',
						position: 'bottom-right'
					});
				} else if (!this.form.detailedBos[0]) {
					this.$notify({
						title: '提示',
						message: `请添加报价明细`,
						type: 'error',
						position: 'bottom-right'
					});
				} else {
					// this.form.contacts.map(item => item.contactPhone).map((item, i) => {
					// 	if (!reg_tel.test(item)) {

					// 		return false;
					// 	}
					// })

					saveOrUpdate(this.form).then(res => {
						console.log(res)
						if (res.code == 200) {
							this.$alert('您的报价单已成功生成，后续会有设计师跟您联系，请耐心等待。', '提交成功', {
								confirmButtonText: '确定',
								type: 'success',
								callback: () => {
									this.$router.push({
										name: 'Reference'
									})
								}
							});
						}
					})
				}
			},

			handleNodeClick(data) {
				console.log(data);
			},

			// 添加联系人
			addContacts() {
				let len = this.form.contacts.length
				if (len < 5) {
					this.form.contacts.push({
						"contactName": "",
						"contactPhone": "",
					});
				} else {
					this.$notify({
						title: '警告',
						message: '最多可以添加五个联系人',
						type: 'warning',
						position: 'bottom-right'
					});
				}
			},

			// 删除联系人
			deleteRow(i) {
				this.form.contacts.splice(i, 1)
			},

			// 千位符
			numberToCurrencyNo(value) {
				if (!value) return 0
				// 获取整数部分
				const intPart = Math.trunc(value)
				// 整数部分处理，增加,
				const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
				// 预定义小数部分
				let floatPart = ''
				// 将数值截取为小数部分和整数部分
				const valueArray = value.toString().split('.')
				if (valueArray.length === 2) { // 有小数部分
					floatPart = valueArray[1].toString() // 取得小数部分
					return intPartFormat + '.' + floatPart
				}
				return intPartFormat + floatPart
			}
		}
	}
</script>

<style lang="less" scoped>
	#newform {
		padding: 50px;
		background-color: #f2f2f2;
		min-height: 100vh;

		.ReferenceForm {
			margin-bottom: 50px;

			.title {
				font-size: 28px;
				line-height: 3;
				color: #181B1A;
			}

			.form {
				>div {
					background-color: #fff;
					padding: 50px;
					border-radius: 20px;
					margin-bottom: 30px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.tit {
					font-size: 24px;
					margin-bottom: 30px;
					line-height: 1;
				}

				.formName {
					background-color: #fff;
					padding: 50px;
					border-radius: 20px;

					.el-input {
						width: 100%;
						max-width: 700px;
					}
				}

				.contacts {

					.contactList {
						margin-bottom: 30px;

						.row {
							display: flex;
							flex-direction: row;
							width: 100%;
							max-width: 1000px;
							margin-bottom: 20px;

							.name,
							.phone {
								flex: 1;
								display: flex;
								flex-direction: row;
								align-items: center;

								span {
									font-size: 18px;
									margin-right: 10px;
								}

								.el-input {
									flex: 1;
								}
							}

							.name {
								margin-right: 50px;
							}

							.delete {
								margin-left: 20px;
								width: 60px;

								.el-button {}
							}
						}
					}
				}

				.detailed {
					/deep/.el-table {
						margin-bottom: 50px;

						.el-table__header-wrapper {
							.cell {
								font-size: 16px;
								color: #181B1A;
							}
						}

						.col1 {
							display: flex;
							flex-direction: row;
							align-items: center;

							.el-image {
								width: 60px;
								height: 60px;
								margin-right: 10px;
							}

							.info {
								display: flex;
								flex-direction: column;
								flex: 1;

								.class {
									font-size: 16px;
									color: #181B1A;
									margin-bottom: 4px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}

								.main {
									display: flex;
									flex-direction: row;

									.size,
									.type {
										font-size: 12px;
										color: rgba(24, 27, 26, 0.6);
									}

									.size {
										margin-right: 50px;
									}
								}
							}
						}

						.col2 {
							display: flex;
							flex-direction: row;
							align-items: center;

							.el-input-number {
								width: 90px;
								margin-right: 5px;
							}
						}
					}


					.TotalPrice {
						text-align: right;
						margin-bottom: 30px;
						font-size: 18px;
						color: #181B1A;

						span {
							font-size: 22px;
						}
					}

					.submit {
						display: flex;
						justify-content: flex-end;

						.el-button {}
					}
				}

			}


		}

		/deep/.popoverCont {
			.contMain {
				width: 600px;
				height: 400px;
			}
		}

		/deep/.subclauses {
			.el-dialog__header {
				height: 0;
				padding: 0;
			}

			.dialogCont {
				display: flex;
				flex-direction: row;

				.tree {
					margin-right: 20px;

					.treeTit {
						font-size: 20px;
						margin-bottom: 20px;
					}

					.treeList {
						width: 200px;

						* {
							transition: all .3s;
						}

						.item {
							font-size: 16px;
							padding: 10px;
							margin-bottom: 10px;
							border-bottom: 1px solid #eee;
							cursor: pointer;

							&:hover {
								background: rgba(104, 46, 220, 0.1);
							}

							&:first-child {
								border-top: 1px solid #eee;
							}

							&:last-child {
								margin: 0;
							}
						}

						.item.active {
							background: rgba(104, 46, 220, 0.7);
							color: #fff;
						}
					}
				}

				.cont {
					flex: 1;
					min-height: 200px;

					.contTit {
						font-size: 20px;
						margin-bottom: 20px;
					}

					.el-checkbox-group {
						.el-checkbox {
							box-sizing: border-box;

							.el-checkbox__input {
								width: 0;
								height: 0;
								overflow: hidden;
							}

							.el-checkbox__label {
								box-sizing: border-box;
								padding: 0;
								background: rgba(104, 46, 220, 0.04);
								border-radius: 16px 16px 16px 16px;
								padding: 4px 25px;
								height: 50px;
								line-height: 42px;
								margin-bottom: 20px;
							}
						}

						.el-checkbox.is-checked {
							.el-checkbox__label {
								background: #682EDC;
								border-radius: 16px 16px 16px 16px;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
</style>